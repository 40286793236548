import React from "react"
import MainLayout from "../components/layout/MainLayout";
import SEO from "../components/seo";
import Home from "../Home"

export default () => (
    // <MainLayout>
    //     <SEO title={`Home`} description={`Football Manager most precise tactic test results by MrLTactics.com.`} />
    //
    // </MainLayout>
  <Home/>
);
